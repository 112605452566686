.accordion-item {
    &.is-closed {
        background: url('../../resources/images/intermediate-svg/chevron-right.svg') no-repeat right top;
    }

    &.is-open {
        background: url('../../resources/images/intermediate-svg/chevron-down.svg') no-repeat right top;
    }

    summary {
        display: flex;
        align-items: center;
    }
}