.product-carousel .slick-prev::before, 
.product-carousel .slick-next::before,
.product-carousel .slick-prev::before, 
.product-carousel .slick-next::before{
    display: none;
}
.product-carousel .slick-prev{
    left: 5%;
    z-index: 1;
}
.product-carousel .slick-next{
    right: 5%;
    z-index: 1;
}
