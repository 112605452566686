/* override slick-carousel default styles, there are additional instances of the slick slider that is affecting this slider*/
.rc-img-carousel .slick-slider .slick-dots li.slick-active button:before,
.rc-text-carousel .slick-slider .slick-dots li.slick-active button:before{
    color: #C7C7C7;
}
.rc-img-carousel .slick-slider .slick-dots li button:before,
.rc-text-carousel .slick-slider .slick-dots li button:before{
    color: #E4E1E1;
    opacity: 1;
    font-size: 13px !important;
}
.rc-img-carousel .slick-prev::before, 
.rc-img-carousel .slick-next::before,
.rc-text-carousel .slick-prev::before, 
.rc-text-carousel .slick-next::before{
    display: none;
}
.rc-img-carousel .slick-prev,
.rc-text-carousel .slick-prev{
    left: -20px;
}
.rc-img-carousel .slick-next,
.rc-text-carousel .slick-next{
    right: -20px;
}
.rc-img-carousel .slick-track,
.rc-text-carousel .slick-track{
    display: flex;
    align-items: center;
}